import { useEffect, useState, useRef } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { history } from "../../index"
import { logoutAction } from '../../actions/auth/authActions';
import { toast } from 'react-toastify';

export default function IdleTimer() {
  const [idleState, setIdleState] = useState('Active')
  const [count, setCount] = useState(0)
  const [remaining, setRemaining] = useState(0)
  const idleTimeOut = window.globalTS.idle_time_out

  const onIdle = () => {
    if (history.location.pathname != "/login") {
      setIdleState('Idle')
      localStorage.setItem("idleState", 'idle')
      const isIdle = localStorage.getItem("idleState")
      if (isIdle === 'idle') {
        document.addEventListener('click', handleClick);
      }
    }
  }

  const onActive = () => {
    setIdleState('Active')
  }

  const onAction = () => {
    setCount(count + 1)
  }
  const { getRemainingTime, } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: idleTimeOut, // 10 minutes in milliseconds,
    throttle: 500
  })
  const handleClick = (event) => {
    const { target } = event;
    console.log('Clicked on', target.tagName);

    if (target.matches('span, img, button,a,input')) {
      // The click event was triggered on a span, img, button,a,input or button element
      console.log('Clicked on', target.tagName);
      toast.error("User has been inactive for 10 minutes", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000
      });
      logoutAction()
      history.push('/login');
      localStorage.clear()
      setTimeout(()=>{
        window.location.reload();
      },2000)
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)
    return () => {
      clearInterval(interval)
      document.body.removeEventListener('click', handleClick);
    }
  })
  return null
}
